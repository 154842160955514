.slotWrapper:nth-child(odd) {
    background-color: rgb(241, 255, 181);
}


.slotWrapper:nth-child(even) {
    background-color: rgb(181, 250, 255);
}


@media only screen and (max-width: 600px) {
    .selectedCourseListWrapper {
        font-size: 0.5rem !important;
    }
}