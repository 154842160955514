.App {
	text-align: center;
	padding: 10px 20px;
	background-color: black;
}

div {
	box-sizing: border-box !important;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.navbar-actions {
  .btn {
    span {
      margin-left: 0.3rem;
    }
  }
}


@media only screen and (max-width: 600px) {
	.navbar-actions {
    display: flex;
    padding: 0;

    .btn {
      padding: 0.1rem 0.3rem;
      text-align: center;
      align-self: center;
    }

    .btn i {
      margin: 0 auto;
    }
    .btn span {
      display: none;
      width: 0;
    }
	}
}
