.slotchoices {
    max-height: 80vh;
    overflow: auto;
}

.slotDropdown {
    background: white;
    padding: 0;
    
    * {
        font-size: small;
    }
}

#courseMultiselectlist {
    * {
        font-size: small;
    }
}

.accordion-item {
    background-color: #e7f1ff;
    max-height: 80vh;
    overflow: auto;

    &::-webkit-scrollbar {
        // display: none;
        width: 0.2rem;
    }
}

.accordion-button {
    border: 1px solid black;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    padding: 0.5rem 1rem;
    font-weight: bold;
}


.course-code-name-wrapper * {
    cursor: pointer !important;

    &:hover {
        opacity: 0.8;
        color: blue;
    }
}

.selected-course {
    background: green;
    color: white;
}


fieldset.slot-choices-fieldset {
    
    label {
        margin-right: 0.4rem;
        cursor: pointer;
        box-sizing: border-box;
    }
}


.selected-slot {
    background: blue !important;
    border: 3px solid white !important;
    color: white !important;
}



@media only screen and (max-width: 600px) {
    .accordion-item {
        font-size: 0.7rem !important;
    }

    .course-code-name-wrapper {
        font-size: 0.6rem;
    }
}