.Profilecomponent {

    .cursor-pointer {
        cursor: pointer;
    }

    body {
        background: rgb(99, 39, 120)
    }
    
    span {
        overflow-wrap: anywhere;
    }

    .form-control:focus {
        box-shadow: none;
        border-color: #BA68C8
    }
    
    .profile-button {
        background: rgb(99, 39, 120);
        box-shadow: none;
        border: none
    }
    
    .profile-button:hover {
        background: #682773
    }
    
    .profile-button:focus {
        background: #682773;
        box-shadow: none
    }
    
    .profile-button:active {
        background: #682773;
        box-shadow: none
    }
    
    .back:hover {
        color: #682773;
        cursor: pointer
    }
    
    .labels {
        font-size: 11px;
        text-align: left;
    }
    
    .add-experience:hover {
        background: #BA68C8;
        color: #fff;
        cursor: pointer;
        border: solid 1px #BA68C8
    }
    
    
    input {
        font-weight: bold;
    }
    
    input:disabled {
        background-color: #0b5ed7;
        color: white;

        &::placeholder {
            opacity: 1.0;
        }
    }

    
}
